import { Component } from '@angular/core';
import { SvgIconComponent } from '../../components/svg-icon/svg-icon.component';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-contact-us',
  standalone: true,
  imports: [
    SvgIconComponent,
    ReactiveFormsModule,
    FormsModule,
    NgClass
  ],
  templateUrl: './contact-us.component.html',
  styleUrl: './contact-us.component.scss'
})
export class ContactUsComponent {
  emailField = new FormControl('', [Validators.required, Validators.email]);

  constructor(private http: HttpClient) {
  }

  subscribeToNewsletter() {
    this.http.post(environment.rootUrl + '/users/contact-us', { email: this.emailField.value }).subscribe(() => {
      this.emailField.reset();
    });
  }
}
