<nav>
  <app-svg-icon iconPath="identity/logo-horizontal" [width]="190" [height]="72" viewBox="0 0 190 72"
                class="cursor-pointer hide-on-phones" (click)="navToRoot()"></app-svg-icon>

  <app-svg-icon iconPath="identity/logo-horizontal" [width]="84" [height]="32" viewBox="0 0 190 72"
                class="cursor-pointer hide-on-big-screen" (click)="navToRoot()"></app-svg-icon>

  <div class="links hide-on-phones">
    <a class="nav-link use-cases-nav background-hover-box" routerLink="/use-cases" routerLinkActive="active">Use
      Cases</a>
    <a class="nav-link security-stack-nav background-hover-box" routerLink="/security-stack" routerLinkActive="active">Security
      Stack</a>
    <app-dropdown-menu label="Support" [items]="supportNavItems" [absolute]="true"></app-dropdown-menu>
    <app-dropdown-menu label="Community" [items]="communityNavItems" [absolute]="true"></app-dropdown-menu>
  </div>

  @if (!dropdownNavOpen()) {
    <app-svg-icon iconPath="icons/menu" class="hide-on-big-screen" (click)="dropdownNavOpen.set(true)"></app-svg-icon>
  } @else {
    <app-svg-icon iconPath="icons/close" class="hide-on-big-screen" (click)="dropdownNavOpen.set(false)"></app-svg-icon>
  }

  <div class="horizontal-container center gap-8  hide-on-phones">
    <button class="btn yellow hidden">LOGIN</button>
    <button class="btn orange" style="cursor: pointer;" ><a href="https://store.tikos.io/" target="_blank" >BUY CHIPS</a></button>
    <div class="hide-on-narrow">
      <button class="btn neon-teal" style="cursor: pointer; position: relative; border: none; background: transparent; padding: 0;">
        <a href="https://apps.apple.com/" target="_blank" style="display: block; position: absolute; top: 0; left: 0; width: 100%; height: 100%; text-decoration: none;">
          <img src="../assets/icons/download-IOS.jpg" alt="Download on the App Store" style="width: 100%; height: 100%; object-fit: cover;">
          <span style="visibility: hidden;">DOWNLOAD iOS</span>
        </a>
      </button>
      <button class="btn neon-teal" style="cursor: pointer; position: relative; border: none; background: transparent; padding: 0;">
        <a href="https://play.google.com/store" target="_blank" style="display: block; position: absolute; top: 0; left: 0; width: 100%; height: 100%; text-decoration: none;">
          <img src="../assets/icons/download-Android.jpg" alt="Download on Google Play" style="width: 100%; height: 100%; object-fit: cover;">
          <span style="visibility: hidden;">DOWNLOAD ANDROID</span>
        </a>
      </button>
    </div>     
    <div class="hide-on-big-screen">
      <button class="btn neon-teal" (click)="openStore()" style="cursor: pointer;" >DOWNLOAD APP</button>
    </div>  
    <button class="btn blue" routerLink="/contact-us" style="cursor: pointer;" >CONTACT US</button>
  </div>

  @if (dropdownNavOpen()) {
    <div class="nav-overlay">
      <div class="dropdown-nav">
        <a class="nav-link use-cases-nav background-hover-box" routerLink="/use-cases" routerLinkActive="active">Use
          Cases</a>
        <!--        <div class="in-nav-menu">-->
        <!--          Clients-->
        <!--          <app-svg-icon iconPath="icons/chevron-down"></app-svg-icon>-->
        <!--        </div>-->
        <a class="nav-link security-stack-nav background-hover-box" routerLink="/security-stack"
           routerLinkActive="active">Security Stack</a>
        <app-dropdown-menu label="Support" [items]="supportNavItems"></app-dropdown-menu>
        <app-dropdown-menu label="Community" [items]="communityNavItems"></app-dropdown-menu>

        <div class="vertical-container gap-16" style="width: 100%; padding: 0 16px">
          <button class="btn yellow hidden">LOGIN</button>
          <button class="btn orange" style="cursor: pointer;" ><a href="https://store.tikos.io/" target="_blank">BUY CHIPS</a></button>
          <button class="btn neon-teal" (click)="openStore()" style="cursor: pointer;" >DOWNLOAD APP</button>
          <button class="btn blue" routerLink="/contact-us" style="cursor: pointer;" >CONTACT US</button>
        </div>
      </div>
    </div>
  }
</nav>

<router-outlet></router-outlet>

<footer>
  <div id="footer-top-part">
    <div class="title">Authenticity. Provenance. Ownership.</div>
    <div class="buttons-block">
      <!-- <ng-template [ngIf]="showButton"> -->
        <button class="btn turquoise" (click)="openStore()">
          DOWNLOAD THE APP
          <app-svg-icon iconPath="icons/call_made"></app-svg-icon>
        </button>
      <!-- </ng-template> -->
    </div> 
  </div> 


  <nav id="bottom-menu">
    <section>
      <div class="menu-header">NAVIGATION</div>
      <menu>
        <li><a routerLink="use-cases" (click)="scrollTop()">Use Cases</a></li>
        <li><a routerLink="security-stack" (click)="scrollTop()">Security Stack</a></li>
        <li><a routerLink="help-center" (click)="scrollTop()">Help Center</a></li>
        <li><a routerLink="submit-bug" (click)="scrollTop()">Submit a Bug</a></li>
      </menu>
    </section>

    <section>
      <div class="menu-header">LEGAL</div>
      <menu>
        <li><a routerLink="terms-of-use" (click)="scrollTop()">Terms of Use</a></li>
        <li><a routerLink="privacy-policy" (click)="scrollTop()">Privacy Policy</a></li>
      </menu>
    </section>

    <section>
      <div class="menu-header">TALK TO US</div>
      <menu>
        <li><a href="mailto:info@tikos.io">info&#64;tikos.io</a></li>
        <li><a routerLink="contact-us" (click)="scrollTop()">Contact Us</a></li>
      </menu>
    </section>
  </nav>

  <section id="footer-bottom-part">
    <app-svg-icon iconPath="identity/logo-horizontal" [width]="190" [height]="72" viewBox="0 0 190 72"
                  class="cursor-pointer"></app-svg-icon>
    <div id="address-and-phones" style="height: 64px">
      <a href="https://maps.app.goo.gl/TsHMPiu6Fo3b8AMF8" target="_blank" class="horizontal-container gap-16">
        <app-svg-icon iconPath="icons/location"></app-svg-icon>
        Park City, UT
      </a>
    </div>
  </section>

  <span id="all-rights-reserved">© 2024 Tikos. All Rights Reserved. </span>
</footer>
