import { Component } from '@angular/core';

@Component({
  selector: 'app-delete-instructions',
  standalone: true,
  imports: [],
  templateUrl: './delete-instructions.component.html',
  styleUrl: './delete-instructions.component.scss'
})
export class DeleteInstructionsComponent {

}
