<div class="page-content smaller-gap">
  <section class="width-100 sub-heading vertical-container align-start">
    <h2 class="page-title">How to delete your account?</h2>
  </section>

  <section class="width-100">
    <ul>
      <li>Go to the Tikos mobile app.</li>
      <li>Open "Profile" tab.</li>
      <li>Click on "Privacy and Security".</li>
      <li>Click on "Delete Account".</li>
      <li>Follow the instructions to delete your account.</li>
    </ul>
  </section>
</div>
