import { Routes } from '@angular/router';
import { MainPageComponent } from "./pages/main-page/main-page.component";
import { PrivacyPolicyComponent } from "./pages/privacy-policy/privacy-policy.component";
import { TermsOfUseComponent } from "./pages/terms-of-use/terms-of-use.component";
import { SecurityStackComponent } from './pages/security-stack/security-stack.component';
import { HelpCenterComponent } from './pages/help-center/help-center.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { SubmitBugComponent } from './pages/submit-bug/submit-bug.component';
import { UseCasesComponent } from './pages/use-cases/use-cases.component';
import {TagVerificationComponent} from "./pages/tag-verification/tag-verification.component";
import {EmailConfirmationResultComponent} from "./pages/email-confirmation-result/email-confirmation-result.component";
import {DeleteInstructionsComponent} from "./pages/delete-instructions/delete-instructions.component";

export const routes: Routes = [
  {
    path: '',
    component: MainPageComponent
  },
  {
    path: 'verify',
    component: TagVerificationComponent
  },
  {
    path: 'tagpt',
    redirectTo: 'verify'
  },
  {
    path: 'tag',
    redirectTo: 'verify'
  },
  {
    path: 'confirm-email/:token',
    component: EmailConfirmationResultComponent
  },
  {
    path: 'use-cases',
    component: UseCasesComponent
  },
  {
    path: 'security-stack',
    component: SecurityStackComponent
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'submit-bug',
    component: SubmitBugComponent
  },
  {
    path: 'help-center',
    component: HelpCenterComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent
  },
  {
    path: 'account/delete',
    component: DeleteInstructionsComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];
